export default {
  en: {
    header: {
      menu: [
        {
          anchor: "about",
          title: "About",
        },
        {
          anchor: "philosophy",
          title: "Philosophy",
        },
        {
          anchor: "expertise",
          title: "Expertise",
        },
      ],
    },
    footer: {
      address: {
        country: "Switzerland",
        locality: "St-Sulpice",
        postalCode: "1025",
        street: "Rue des Jordils 40",
      },
      text: "For additional information, please send us an e-mail.",
    },
    page404: {
      seoTitle: "404: Page not found",
      uptitle: "404 Error",
      title: "Oops, this page does not exist…",
      content:
        "The page you are looking for cannot be found or no longer exists. Please go back to the <a class='font-bold' href='/'>home page</a> of our website or <a class='font-bold' href='mailto:%email%'>contact us</a> if you have any questions.",
    },
  },
  fr: {
    header: {
      menu: [
        {
          anchor: "presentation",
          title: "À propos",
        },
        {
          anchor: "philosophie",
          title: "Philosophie",
        },
        {
          anchor: "competences",
          title: "Compétences",
        },
      ],
    },
    footer: {
      address: {
        country: "Suisse",
        locality: "St-Sulpice",
        postalCode: "1025",
        street: "Rue des Jordils 40",
      },
      text:
        "Vous désirez en savoir plus sur le groupe ABLV, nous privilégions le premier contact par e-mail.",
    },
    page404: {
      seoTitle: "404: Page introuvable",
      uptitle: "Erreur 404",
      title: "Oups, la page ne semble pas exister…",
      content:
        "La page que vous cherchez semble introuvable ou n'existe plus. Nous vous invitons à revenir à la <a class='font-bold' href='/'>page d’accueil</a> de notre site ou à nous <a class='font-bold' href='mailto:%email%'>contacter</a> en cas de questions.",
    },
  },
}
