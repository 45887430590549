import React, { useState } from "react"
import { Helmet } from "react-helmet"

export const PageContext = React.createContext()

export default ({ currentLanguage, translation, path, children }) => {
  // GDPR Services
  const [gdprServices, setGdprServices] = useState({})

  const defaultContext = {
    currentLanguage,
    translation,
    path,
    gdprServices,
    setGdprServices,
  }

  return (
    <PageContext.Provider value={defaultContext}>
      <Helmet>
        {gdprServices.gtm && gdprServices.gtm.isAccepted && (
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WM2SRN4');`}
          </script>
        )}
      </Helmet>
      {children}
    </PageContext.Provider>
  )
}
