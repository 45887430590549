/* eslint jsx-a11y/no-static-element-interactions: off */
/* eslint jsx-a11y/click-events-have-key-events: off */

import React, { useState, useRef, useEffect, useContext } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { PageContext } from "./page-context"
import i18n from "../data/i18n"

// Assets
import ablvImage from "../images/ablv.svg"

const Header = ({ email }) => {
  const { currentLanguage, translation } = useContext(PageContext)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const refEl = useRef(null)
  const isMenuOpenRef = useRef(false)

  /* eslint react-hooks/exhaustive-deps: off */
  useEffect(() => {
    window.addEventListener("resize", handleBodyScrollLock)

    return () => {
      window.removeEventListener("resize", handleBodyScrollLock)
    }
  }, [])

  useEffect(() => {
    isMenuOpen
      ? disableBodyScroll(refEl.current)
      : enableBodyScroll(refEl.current)
    isMenuOpenRef.current = isMenuOpen
  }, [isMenuOpen])

  const toggleMenu = () => {
    if (!isMenuOpen) {
      window.scrollTo(0, 0)
    }

    setIsMenuOpen(!isMenuOpen)
  }

  const handleBodyScrollLock = () => {
    if (isMenuOpenRef && window.innerWidth >= 1024) {
      setIsMenuOpen(false)
    }
  }

  const handleLinkClick = e => {
    if (e.target.tagName === "A") {
      setIsMenuOpen(false)
    }
  }

  return (
    <header
      onClick={handleLinkClick}
      ref={refEl}
      className={`py-8 text-white lg:py-16 min-h-16 header ${
        isMenuOpen ? "header--is-menu-open" : ""
      }`}
    >
      <div className="flex items-center justify-between c-container lg:grid lg:grid-cols-12 lg:gap-4">
        <div className="col-span-3 header__logo">
          <Link to="/" className="inline-block">
            <img src={ablvImage} alt="ABLV" />
          </Link>
        </div>
        <nav className="items-center flex-1 col-span-9 space-y-10 text-sm font-bold xs:space-y-16 lg:space-y-0 lg:flex header__nav">
          {i18n[currentLanguage].header.menu.map((item, index) => (
            <Link
              key={index}
              className="text-2xl sm:text-3xl lg:text-sm lg:mr-16"
              to={`/${currentLanguage}/#${item.anchor}`}
            >
              {item.title}
            </Link>
          ))}
          <a
            href={`${
              translation?.hrefLang === "en" ? translation.href : "/en"
            }`}
            className={`header__lang header__lang--en text-base sm:text-lg lg:text-sm lg:mt-0 lg:ml-auto lg:mr-4 ${
              currentLanguage === "en" ? "is-active" : ""
            }`}
          >
            EN
          </a>
          <a
            href={`${
              translation?.hrefLang === "fr" ? translation.href : "/fr"
            }`}
            className={`header__lang header__lang--fr text-base sm:text-lg lg:text-sm lg:mr-16 ${
              currentLanguage === "fr" ? "is-active" : ""
            }`}
          >
            FR
          </a>

          <Link
            to={`/${currentLanguage}/#contact`}
            className="inline-block text-center button"
          >
            Contact
          </Link>
        </nav>

        <button
          onClick={toggleMenu}
          aria-label="Menu"
          className={`lg:hidden nav-toggle ${isMenuOpen ? "is-active" : ""}`}
        >
          <span className="nav-toggle__box">
            <span className="nav-toggle__inner"></span>
          </span>
        </button>
      </div>
    </header>
  )
}

Header.propTypes = {
  email: PropTypes.string.isRequired,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
