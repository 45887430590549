import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import PropTypes from "prop-types"

const Hero = ({ uptitle, title, image }) => {
  return (
    <div className="pt-8 text-white bg-black lg:pt-16 hero">
      <div className="grid grid-cols-12 col-gap-4 pt-32 pb-16 lg:py-32 lg:py-40 c-container hero__inner lg:col-gap-8">
        <h2 className="col-span-12 uptitle">{uptitle}</h2>
        <h1
          className="col-span-12 text-4xl font-bold lg:col-span-12 sm:text-5xl md:text-7xl lg:text-9xl xl:text-10xl"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h1>
      </div>
      <Img
        className="hero__bg"
        loading="eager"
        style={{ position: "absolute" }}
        objectPosition="right center"
        placeholderStyle={{ objectPosition: "right center" }}
        {...image}
      />
    </div>
  )
}

Hero.propTypes = {
  uptitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default Hero
