import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import i18n from "../data/i18n"

// Assets
import superhuitImage from "../images/superhuit.svg"

const Footer = ({ organization, buildTime, lang, legalPages }) => (
  <footer
    id="contact"
    className="pt-20 text-white bg-black lg:pt-28 xl:pt-40 footer"
  >
    {/* Columns */}
    <div className="grid grid-cols-12 c-container gap-x-8">
      {/* Column */}
      <h3 className="col-span-12 uptitle">Contact</h3>
      <div className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3">
        <h1 className="text-3xl">{organization.name}</h1>
        <p className="mt-2 lg:text-lg">
          {i18n[lang].footer.address.street} <br />
          {i18n[lang].footer.address.postalCode}{" "}
          {i18n[lang].footer.address.locality} <br />
          {i18n[lang].footer.address.country}
        </p>
      </div>
      {/* Column */}
      <div className="col-span-10 mt-12 sm:col-span-7 md:mt-0 lg:col-span-5 md:col-span-6 xl:col-span-3 xl:col-start-5">
        <p className="lg:text-lg">{i18n[lang].footer.text}</p>
        <a
          className="inline-block px-6 py-3 mt-8 text-sm font-bold text-black bg-white md:mt-16"
          href={`mailto:${organization.contacts.email}`}
        >
          {organization.contacts.email}
        </a>
      </div>
    </div>
    <div className="flex col-gap-10 mt-20 text-sm lg:mt-32 c-container">
      {legalPages[lang].map(page =>
        page.link.startsWith("#") ? (
          <a href={page.link} key={page.link}>
            {page.title}
          </a>
        ) : (
          <Link to={page.link} className="inline-block" key={page.link}>
            {page.title}
          </Link>
        )
      )}
    </div>
    <div className="pb-10 mt-6 text-sm sm:flex c-container sm:flex-row sm:items-center sm:justify-between footer__credits">
      <span className="footer__copywrite">
        © {buildTime} {organization.name}
      </span>
      <span className="flex items-center mt-5 sm:mt-0 footer__author">
        handcrafted by
        <a
          className="ml-4"
          href="https://superhuit.ch"
          target="_blank"
          rel="noreferrer"
        >
          <img src={superhuitImage} alt="Superhuit" />
        </a>
      </span>
    </div>
  </footer>
)

Footer.propTypes = {
  organization: PropTypes.object.isRequired,
}

export default Footer
