import "./styles.scss"

import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"

import { PageContext } from "../../page-context"
import mergeDeep from "../../../helpers/merge-deep"
// import ButtonPrimary from "@components/atoms/buttons/ButtonPrimary"
import DEFAULT_CONFIG from "./default-config.json"

const GdprBanner = ({
  bannerDismissed,
  texts,
  onAcceptClick,
  onPersonalizeClick,
  onRejectClick,
}) => {
  const [configs] = useState(() =>
    mergeDeep(DEFAULT_CONFIG, {
      texts,
    })
  )

  const [isHidden, setIsHidden] = useState(true)
  const { currentLanguage } = useContext(PageContext)

  useEffect(() => {
    if (bannerDismissed) hide()
    else show()
  }, [bannerDismissed])

  const handleAcceptClick = event => {
    event.stopPropagation()

    onAcceptClick()

    setIsHidden(true)
  }

  const handleRejectClick = event => {
    event.stopPropagation()

    onRejectClick()

    setIsHidden(true)
  }

  const show = () => {
    setIsHidden(false)
  }

  const hide = () => {
    setIsHidden(true)
  }

  return (
    <div
      className="banner"
      role="dialog"
      aria-live="polite"
      aria-hidden={isHidden}
      aria-label="cookie-law-banner:title"
      aria-describedby="cookie-law-banner:desc"
    >
      {configs.texts?.[currentLanguage].title ? (
        <p id="cookie-law-banner:title" className="uptitle title">
          {configs.texts?.[currentLanguage].title}
        </p>
      ) : null}

      <div id="cookie-law-banner:desc" className="message">
        <p>{configs.texts?.[currentLanguage].message}</p>
      </div>

      <div className="buttons">
        <button className="acceptButton" onClick={handleAcceptClick}>
          {configs.texts?.[currentLanguage].acceptAll}
        </button>

        <button
          className="personalizeButton"
          onClick={onPersonalizeClick}
          data-target
        >
          {configs.texts?.[currentLanguage].personalize}
        </button>

        <button className="rejectButton" onClick={handleRejectClick}>
          {configs.texts?.[currentLanguage].rejectAll}
        </button>
      </div>
    </div>
  )
}

GdprBanner.propTypes = {
  bannerDismissed: PropTypes.bool.isRequired,
  texts: PropTypes.object.isRequired,
  onPersonalizeClick: PropTypes.func.isRequired,
  onAcceptClick: PropTypes.func.isRequired,
  onRejectClick: PropTypes.func.isRequired,
}

export default GdprBanner
