import "./styles.scss"

import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  useContext,
} from "react"
import PropTypes from "prop-types"

import { PageContext } from "../../page-context"
import GdprCategory from "../GdprCategory"
import mergeDeep from "../../../helpers/merge-deep"
// import ButtonPrimary from "@components/atoms/buttons/ButtonPrimary"
import DEFAULT_CONFIG from "./default-config.json"

const GdprModal = React.forwardRef(
  (
    { categories, texts, onModalSaved, onModalClosed, onCategoryChange },
    ref
  ) => {
    const [isHidden, setIsHidden] = useState(true)
    const [configs] = useState(() =>
      mergeDeep(DEFAULT_CONFIG, {
        texts,
      })
    )
    const elRef = useRef(null)
    const closeRef = useRef(null)
    const categoriesRef = useRef([])
    const id = useRef(null)
    const { currentLanguage } = useContext(PageContext)

    useEffect(() => {
      id.current = genId()
    }, [])

    // Allows to call these functions from the parent
    useImperativeHandle(ref, () => ({
      open,
      setCategoryEnabled,
    }))

    const bindEvents = () => {
      document.addEventListener("focusin", onLostFocus)
    }

    const unbindEvents = () => {
      document.removeEventListener("focusin", onLostFocus)
    }

    // ##############################
    // #region Event handler
    // ##############################

    const onSave = event => {
      event.stopPropagation()
      save()
    }

    const onClose = event => {
      event.stopPropagation()
      close()
    }

    const onLostFocus = event => {
      if (!elRef.current) return
      if (elRef.current.contains(event.target)) return
      closeRef.current.focus()
    }

    // ##############################
    // #endregion
    // ##############################

    const open = () => {
      if (isHidden === false) return

      setIsHidden(false)
      closeRef.current.focus()

      bindEvents()
    }

    const save = () => {
      onModalSaved()
      close()
    }

    const close = () => {
      if (isHidden === true) return

      setIsHidden(true)
      onModalClosed()

      unbindEvents()
    }

    const setCategoryEnabled = (category, enabled = false) => {
      categoriesRef.current
        .find(cat => cat.getId() === category.id)
        .setEnabled(enabled)
    }

    /**
     * Generate unique IDs for use as pseudo-private/protected names.
     * Similar in concept to
     * <http://wiki.ecmascript.org/doku.php?id=strawman:names>.
     *
     * The goals of this function are twofold:
     *
     * * Provide a way to generate a string guaranteed to be unique when compared
     *   to other strings generated by this function.
     * * Make the string complex enough that it is highly unlikely to be
     *   accidentally duplicated by hand (this is key if you're using `ID`
     *   as a private/protected name on an object).
     *
     * Use:
     *
     *     var privateName = ID();
     *     var o = { 'public': 'foo' };
     *     o[privateName] = 'bar';
     *
     * @source https://gist.github.com/gordonbrander/2230317;
     */
    const genId = () => {
      // Math.random should be unique because of its seeding algorithm.
      // Convert it to base 36 (numbers + letters), and grab the first 9 characters
      // after the decimal.
      return "_" + Math.random().toString(36).substr(2, 9)
    }

    return (
      <section
        className="modal"
        role="dialog"
        aria-hidden={isHidden}
        tabIndex={-1}
        id={id.current}
        ref={elRef}
      >
        <div
          className="dialog"
          role="document"
          aria-labelledby="cookie-law-modal:title"
        >
          <div className="content">
            <button
              className="close"
              onClick={onClose}
              ref={closeRef}
              data-target
            >
              <svg viewBox="-1 -1 18 18">
                <title>{configs.texts?.[currentLanguage].close}</title>
                <line x1="16" y1="0" x2="0" y2="16"></line>
                <line x1="16" y1="16" x2="0" y2="4.4408921e-16"></line>
              </svg>
            </button>
            <div className="modal__body">
              <header className="head">
                <h4 id="cookie-law-modal:title" className="title">
                  {configs.texts?.[currentLanguage].title}
                </h4>
                <p
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: configs.texts?.[currentLanguage].description,
                  }}
                />
              </header>
              <ul className="categories">
                {categories.map((cat, index) => (
                  <GdprCategory
                    key={index}
                    cat={cat}
                    handleChange={onCategoryChange}
                    ref={categoryRef =>
                      (categoriesRef.current[index] = categoryRef)
                    }
                  />
                ))}
              </ul>
              <footer className="modal__footer">
                {/* <ButtonPrimary
									className="save"
									type="gdpr"
									onClick={onSave}
									text={configs.texts?.[currentLanguage].save}
								/> */}
                <button className="save" onClick={onSave}>
                  {configs.texts?.[currentLanguage].save}
                </button>
              </footer>
            </div>
          </div>
        </div>
      </section>
    )
  }
)

GdprModal.propTypes = {
  categories: PropTypes.array.isRequired,
  texts: PropTypes.object.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  onModalSaved: PropTypes.func.isRequired,
  onModalClosed: PropTypes.func.isRequired,
}

export default GdprModal
