import "./styles.scss"

import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from "react"
import cx from "classnames"
import PropTypes from "prop-types"

import { PageContext } from "../../page-context"

import mergeDeep from "../../../helpers/merge-deep"
import DEFAULT_CONFIG from "./default-config.json"

const GdprCategory = React.forwardRef(({ cat, handleChange }, ref) => {
  const [configs] = useState(() =>
    mergeDeep(DEFAULT_CONFIG, {
      ...cat,
    })
  )
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [isEnabled, setIsEnabled] = useState(configs.enabled)

  const { currentLanguage } = useContext(PageContext)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleChange({
      enabled: isEnabled,
      id: configs.id,
    })
  }, [isEnabled])
  /* eslint-enable react-hooks/exhaustive-deps */

  // Allows to call these functions from the parent
  useImperativeHandle(ref, () => ({
    getId,
    setEnabled,
  }))

  // ##############################
  // #region Event handler
  // ##############################

  const onToggle = event => {
    event.stopPropagation()

    setIsCollapsed(!isCollapsed)
  }

  const onChange = event => {
    event.stopPropagation()

    setIsEnabled(event.target.checked)
  }

  const stopPropagation = event => {
    event.stopPropagation()
  }

  // ##############################
  // #endregion
  // ##############################

  const getId = () => {
    return configs.id
  }

  const setEnabled = (enabled = false) => {
    if (configs.mandatory) return
    setIsEnabled(enabled)
  }

  return (
    <li className={cx("category", !isCollapsed && "isExpanded")}>
      <div
        className="tab"
        role="button"
        tabIndex={0}
        onClick={onToggle}
        onKeyDown={ev => ev.keyCode === 13 && onToggle(ev)}
        data-target
      >
        <button className="title">
          <span>{configs?.texts[currentLanguage].title}</span>
        </button>
        {configs?.mandatory ? (
          <span className="caption">
            {configs.texts[currentLanguage].alwaysEnabled}
          </span>
        ) : (
          <div
            className="switch"
            role="button"
            tabIndex={0}
            onClick={stopPropagation}
            onKeyDown={ev => ev.keyCode === 13 && stopPropagation(ev)}
            data-target
          >
            <input
              type="checkbox"
              className="checkbox"
              id={`cookie-law-category-checkbox-${configs?.id}`}
              checked={isEnabled}
              onChange={onChange}
            />
            <label
              className="label"
              htmlFor={`cookie-law-category-checkbox-${configs?.id}`}
            >
              {configs.texts[currentLanguage][isEnabled ? "disable" : "enable"]}
            </label>
            <span className="status">
              {
                configs.texts[currentLanguage][
                  isEnabled ? "enabled" : "disabled"
                ]
              }
            </span>
          </div>
        )}
      </div>
      <div className="content" aria-hidden={isCollapsed}>
        <div className="desc">
          {configs?.texts[currentLanguage].description}
        </div>
      </div>
    </li>
  )
})

GdprCategory.propTypes = {
  cat: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default GdprCategory
