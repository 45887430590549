import "./styles.scss"

import React, { useEffect, useRef, useState, useContext } from "react"
import Cookies from "js-cookie"
import PropTypes from "prop-types"

import { PageContext } from "../page-context"
import GdprBanner from "./GdprBanner"
import GdprModal from "./GdprModal"

/**
 * Constants
 */
const ACCEPTED_VALUE = "yes"
const REFUSED_VALUE = "no"
const COOKIES_OPTIONS = {
  expires: 365,
  sameSite: "Lax",
}

const Gdpr = ({ configs = {} }) => {
  const [categories, setCategories] = useState([])
  const [bannerDismissed, setBannerDismissed] = useState(true)

  /* eslint-disable-next-line no-unused-vars */
  const { gdprServices, setGdprServices } = useContext(PageContext)

  const services = useRef({})

  const modalRef = useRef(null)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const cats = getCategoriesSettings()
    setCategories(cats)

    const hasCookies = Cookies.get(`${configs.cookieName}_banner`) === "dismiss"

    setBannerDismissed(hasCookies)

    window.addEventListener("hashchange", onHashChange)

    return () => {
      window.removeEventListener("hashchange", onHashChange)
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (bannerDismissed) {
      Cookies.set(`${configs.cookieName}_banner`, "dismiss", COOKIES_OPTIONS)
    } else {
      Cookies.remove(`${configs.cookieName}_banner`)
    }
  }, [bannerDismissed])
  /* eslint-enable react-hooks/exhaustive-deps */

  // ##############################
  // #region Event handler
  // ##############################

  const onPersonalizeClick = () => {
    modalRef.current.open()
  }

  const onHashChange = (event /*url*/) => {
    if (event.newURL.indexOf(`#${configs.hash}`) === -1) return

    modalRef.current.open()

    window.history.replaceState(
      "",
      "Cookie Manager",
      window.location.pathname + window.location.search
    )
  }

  const onModalClosed = () => {
    if (window.location.hash.indexOf(`#${configs.hash}`) === -1) return

    window.history.replaceState(
      "",
      document.title,
      window.location.pathname + window.location.search
    )
  }

  const onModalSaved = () => {
    setBannerDismissed(true)
  }

  const onCategoryChange = ({ enabled, id }) => {
    const changedCat = configs.categories.find(cat => cat.id === id)
    setCategoryCookie(changedCat, enabled)
  }

  // ##############################
  // #endregion
  // ##############################

  const acceptAll = () => {
    setBannerDismissed(true)
    configs.categories.forEach(cat => {
      setCategoryCookie(cat, true)
      modalRef.current.setCategoryEnabled(cat, true)
    })
  }

  const rejectAll = () => {
    setBannerDismissed(true)
    configs.categories.forEach(cat => {
      setCategoryCookie(cat, false)
      modalRef.current.setCategoryEnabled(cat, false)
    })
  }

  const setCategoryCookie = (category, accepted = false) => {
    // No need to set mandatory categories
    // as we cannot choose
    if (category.mandatory) return

    Cookies.set(
      `${configs.cookieName}_${category.id}_accepted`,
      accepted ? ACCEPTED_VALUE : REFUSED_VALUE,
      COOKIES_OPTIONS
    )

    if (category.services) {
      category.services.forEach(service => {
        services.current = {
          ...services.current,
          [service]: {
            isAccepted: accepted,
          },
        }
      })

      setGdprServices(services.current) // Needs to be set from a ref to avoid overriding state that wasn't updated yet
    }
  }

  // TODO :: Move to category file ??
  const getCategoriesSettings = () => {
    return configs.categories.map(cat => {
      const cookie = Cookies.get(`${configs.cookieName}_${cat.id}_accepted`)
      const enabled = cookie === ACCEPTED_VALUE

      return { ...cat, enabled }
    })
  }

  return (
    <div className="gdpr">
      <GdprBanner
        bannerDismissed={bannerDismissed}
        texts={configs.banner.texts}
        onPersonalizeClick={onPersonalizeClick}
        onAcceptClick={acceptAll}
        onRejectClick={rejectAll}
      />
      <GdprModal
        ref={modalRef}
        categories={categories}
        texts={configs.modal.texts}
        onCategoryChange={onCategoryChange}
        onModalSaved={onModalSaved}
        onModalClosed={onModalClosed}
      />
    </div>
  )
}

Gdpr.propTypes = {
  configs: PropTypes.object,
}

export default Gdpr
