/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import PageContext from "./page-context"
import Gdpr from "./Gdpr"
import gdpr from "../data/gdpr"
import "../css/global.scss"

// Components
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, lang, translation = null, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          organization {
            name
            url
            contacts {
              email
            }
          }
          legalPages {
            en {
              link
              title
            }
            fr {
              link
              title
            }
          }
        }
        buildTime
      }
    }
  `)

  const { title, organization, legalPages } = data.site.siteMetadata

  const buildTime = new Date(data.site.buildTime).getFullYear()

  return (
    <PageContext
      currentLanguage={lang}
      translation={translation}
      path={location.pathname}
    >
      {process.env.GATSBY_STAGING_DEPLOY === "true" && (
        <Helmet
          meta={[
            {
              name: `robots`,
              content: "noindex",
            },
          ]}
        ></Helmet>
      )}
      <Header
        siteTitle={title}
        email={organization.contacts.email}
        lang={lang}
        translation={translation}
      />
      <main>{children}</main>
      <Footer
        organization={organization}
        buildTime={buildTime}
        lang={lang}
        legalPages={legalPages}
      />
      <Gdpr configs={gdpr} />
    </PageContext>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string.isRequired,
}

export default Layout
